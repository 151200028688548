import React, { useEffect } from "react"

const MessengerChat = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      // eslint-disable-next-line
      FB.init({
        xfbml: true,
        version: "v18.0",
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/de_DE/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
    var chatbox = document.getElementById("fb-customer-chat")
    chatbox.setAttribute("page_id", "108093725323738")
    chatbox.setAttribute("attribution", "biz_inbox")
  }, [])
  return (
    <>
      <div id="fb-root" />
      <div id="fb-customer-chat" class="fb-customerchat" />
    </>
  )
}

export default MessengerChat
